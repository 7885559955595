import Phaser from 'phaser'
import Scene1 from './Scene1'
import Scene2 from './Scene2'

export const config = {
    width: 256,
    height: 272,
    backgroundColor: '000000',
    scene: [Scene1, Scene2],
    pixelArt: true,
    physics: {
        default: 'arcade',
        arcade: {
            debug: false
        }
    }
}

export const gameSettings = {
    playerSpeed: 200
}

export const game = new Phaser.Game(config)
