import React, { useEffect } from 'react';
import { game } from './client/Game'

function App() {
  document.title = 'Color Run!'
  useEffect(() => {
    console.log(game)
  })
  
  return (
    <div>
    </div>
  )
}

export default App;
