import Phaser from 'phaser'

class Scene1 extends Phaser.Scene {
  constructor() {
    super('bootGame');
  }

  preload(){
    this.load.image('background', process.env.PUBLIC_URL + '/images/background.png')
    this.load.spritesheet('ship', process.env.PUBLIC_URL +  '/sprites/ship.png', {
      frameWidth: 16,
      frameHeight: 16
    })
    this.load.spritesheet('ship2', process.env.PUBLIC_URL + '/sprites/ship2.png', {
      frameWidth: 32,
      frameHeight: 16
    })
    this.load.spritesheet('ship3', process.env.PUBLIC_URL + '/sprites/ship3.png', {
      frameWidth: 32,
      frameHeight: 32
    })
    this.load.spritesheet('explosion', process.env.PUBLIC_URL + '/sprites/explosion.png', {
      frameWidth: 16,
      frameHeight: 16
    })
    this.load.spritesheet('power-up', process.env.PUBLIC_URL + '/sprites/power-up.png', {
      frameWidth: 16,
      frameHeight: 16
    })
    this.load.spritesheet('player', process.env.PUBLIC_URL + '/sprites/player.png', {
      frameWidth: 16,
      frameHeight: 24
    })
    this.load.spritesheet('beam', process.env.PUBLIC_URL + '/sprites/beam.png', {
      frameWidth: 16,
      frameHeight: 16
    })

    this.load.bitmapFont('pixelFont', process.env.PUBLIC_URL + '/font/font.png', process.env.PUBLIC_URL + '/font/font.xml')
  }

  create() {
    this.scene.start('playGame')
    
    this.anims.create({
      key: 'ship1_anim',
      frames: this.anims.generateFrameNumbers('ship'),
      frameRate: 20,
      repeat: -1
    })
    this.anims.create({
      key: 'ship2_anim',
      frames: this.anims.generateFrameNumbers('ship2'),
      frameRate: 20,
      repeat: -1
    })
    this.anims.create({
      key: 'ship3_anim',
      frames: this.anims.generateFrameNumbers('ship3'),
      frameRate: 20,
      repeat: -1
    })
    this.anims.create({
      key: 'explode',
      frames: this.anims.generateFrameNumbers('explosion'),
      frameRate: 20,
      repeat: 0,
      hideOnComplete: true
    })
    this.anims.create({
      key: 'red',
      frames: this.anims.generateFrameNumbers('power-up', {
          start: 0,
          end: 1
      }),
      frameRate: 20,
      repeat: -1
    })
    this.anims.create({
      key: 'gray',
      frames: this.anims.generateFrameNumbers('power-up', {
          start: 2,
          end: 3
      }),
      frameRate: 20,
      repeat: -1
    })
    this.anims.create({
      key: 'thrust',
      frames: this.anims.generateFrameNumbers('player'),
      frameRate: 20,
      repeat: -1
    })
    this.anims.create({
      key: 'beam_anim',
      frames: this.anims.generateFrameNumbers('beam'),
      frameRate: 20,
      repeat: -1
    })
  }
}

export default Scene1