import Phaser from 'phaser'
import { config, gameSettings } from './Game'
import Beam from './Beam'
import Explosion from './Explosion'

class Scene2 extends Phaser.Scene {
    constructor() {
        super('playGame')
    }

    create() {
        this.background = this.add.tileSprite(0, 0, config.width, config.height, 'background')
        this.background.setOrigin(0, 0)
        
        this.ship1 = this.add.sprite(config.width / 2 - 50, config.height / 2, 'ship')
        this.ship2 = this.add.sprite(config.width / 2, config.height / 2, 'ship2')
        this.ship3 = this.add.sprite(config.width / 2 + 50, config.height / 2, 'ship3')

        this.enemies = this.physics.add.group()
        this.enemies.add(this.ship1)
        this.enemies.add(this.ship2)
        this.enemies.add(this.ship3)

        this.powerUps = this.physics.add.group()
        this.physics.add.collider(this.powerUps, this.powerUps)

        this.createPowerUps(5)

        this.ship1.play('ship1_anim')
        this.ship2.play('ship2_anim')
        this.ship3.play('ship3_anim')
        this.ship1.setInteractive()
        this.ship2.setInteractive()
        this.ship3.setInteractive()

        this.input.on('gameobjectdown', this.destroyShip, this)

        this.player = this.physics.add.sprite(config.width / 2 - 8, config.height - 64, 'player')
        this.player.play('thrust')
        this.player.powerUps = 0
        this.cursorKeys = this.input.keyboard.createCursorKeys()
        this.player.setCollideWorldBounds(true)

        this.spacebar = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE)
        this.projectiles = this.add.group()

        this.physics.add.collider(this.projectiles, this.powerUps, (projectile, powerUp) => projectile.destroy())

        this.physics.add.overlap(this.player ,this.powerUps, this.pickPowerUp, null, this)
        this.physics.add.overlap(this.player, this.enemies, this.hurtPlayer, null, this)
        this.physics.add.overlap(this.projectiles, this.enemies, this.hitEnemy, null, this)

        const graphics = this.add.graphics()
        graphics.fillStyle('Black')
        graphics.fillRect(0, 0, config.width, 20)

        this.score = 0
        this.scoreLabel = this.add.bitmapText(10, 5, 'pixelFont', 'SCORE', 16)
    }

    createPowerUps(maxObjects) {
        const currentObjects = this.powerUps.getChildren().length
        for (let i = currentObjects; i <= maxObjects; i++) {
            const powerUp = this.physics.add.sprite(16, 16, 'power-up')
            this.powerUps.add(powerUp)
            powerUp.setRandomPosition(0, 0, config.width, config.width)

            if (Math.random() > 0.5) {
                powerUp.play('red')
            } else {
                powerUp.play('gray')
            }
            powerUp.setVelocity(100, 100)
            powerUp.setCollideWorldBounds(true)
            powerUp.setBounce(1)
            console.log('powerup created')
        }
    }

    zeroPad(number, size) {
        let stringNumber = number + ''
        while (stringNumber.length < (size || 2)) {
            stringNumber = '0' + stringNumber
        }
        return stringNumber
    }

    hitEnemy(projectile, enemy) {
        new Explosion(this, enemy.x, enemy.y)
        projectile.destroy()
        this.resetShipPosition(enemy)
        this.score += 15
        const scoreFormatted = this.zeroPad(this.score, 6)
        this.scoreLabel.text = 'SCORE ' + scoreFormatted

        if (Math.random() < 0.25 && this.player.powerUps < 5) {
            this.createPowerUps(0)
        }
    }

    hurtPlayer(player, enemy) {
        this.resetShipPosition(enemy)

        if (this.player.alpha < 1) {
            return
        }

        new Explosion(this, player.x, player.y)

        player.disableBody(true, true)
        
        this.time.addEvent({
            delay: 1000,
            callback: this.resetPlayer,
            callbackScope: this,
            loop: false
        })
    }

    resetPlayer() {
        this.score = 0
        this.scoreLabel.text = 'SCORE'
        const x = config.width / 2 - 8
        const y = config.height + 64
        this.player.enableBody(true, x, y, true, true)
        
        this.player.alpha = 0.5

        this.player.powerUps = 0

        this.tweens.add({
            targets: this.player,
            y: config.height - 64,
            ease: 'Power1',
            duration: 1500,
            repeat: 0,
            onComplete: () => this.player.alpha = 1,
            callbackScope: this
        })
    }

    pickPowerUp(player, powerUp) {
        const position = this.powerUps.getChildren().indexOf(powerUp)
        this.powerUps.getChildren().splice(position, 1)
        powerUp.disableBody(true, true)
        player.powerUps += 1
    }

    movePlayerManager() {
        if (this.cursorKeys.left.isDown) {
            this.player.setVelocityX(-gameSettings.playerSpeed)
        } else if (this.cursorKeys.right.isDown) {
            this.player.setVelocityX(gameSettings.playerSpeed)
        } else {
            this.player.setVelocityX(0)
        }

        if (this.cursorKeys.up.isDown) {
            this.player.setVelocityY(-gameSettings.playerSpeed)
        } else if (this.cursorKeys.down.isDown) {
            this.player.setVelocityY(gameSettings.playerSpeed)
        } else {
            this.player.setVelocityY(0)
        }
    }

    shootBeam() {
        new Beam(this, 0, 0)
        for (let i = 1; i <= this.player.powerUps; i++) {
            new Beam(this, 50 * i, 30)
            new Beam(this, -50 * i, -30)
        }
    }

    moveShip(ship, speed) {
        ship.y += speed
        if (ship.y > config.height) {
            this.resetShipPosition(ship)
        }
    }

    resetShipPosition(ship) {
        ship.y = 0
        const randomX = Phaser.Math.Between(0, config.width)
        ship.x = randomX
        
    }

    destroyShip(pointer, gameObject) {
        gameObject.setTexture('explosion')
        gameObject.play('explode')
    }

    update() {
        this.moveShip(this.ship1, 0.7)
        this.moveShip(this.ship2, 1)
        this.moveShip(this.ship3, 1.7)

        this.background.tilePositionY -= 0.5

        this.movePlayerManager()

        if (Phaser.Input.Keyboard.JustDown(this.spacebar) && this.player.active) {
            this.shootBeam()
        }
        for (let i = 0; i < this.projectiles.getChildren().length; i++) {
            const beam = this.projectiles.getChildren()[i]
            beam.update()
        }
    }
}

export default Scene2